import { Serializable } from "core/shared/serializable.model";
import { MenuPage } from "core/components/side-menu/menu-page";

export class User extends Serializable {

    public id: string = null;
    public firstName: string = null;
    public email: string = null;
    public image: any = null;
    public password: string = null;
    public password_confirmation: string = null;
    public authenticationType: string = null;
    public enableEmailNotifications: boolean = null;

    public pages: MenuPage[] = [];

    constructor(data: any = {}) {
        super();
        this.serialize(data);
        this.setPages();
    }

    get httpData() {
        return {
            id: this.id,
            firstName: this.firstName,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation
        };
    }

    get name() {
        const arrName = this.firstName.split(" ");
        return `${arrName[0]} ${arrName[arrName.length - 1]}`;
    }


    get authenticationColor() {
        switch(this.authenticationType) {
            case 'local':
                return 'medium';
            default:
                return 'primary';
        }
    }

    public get defaultPage() {
        let page = this.pages.find( page => page.default);

        if (page) {
            return page.url;
        }

        return '/welcome';
    }

    private setPages() {
        this.pages = this.adminPages;
    }

    public get adminPages() {
        return [
            new MenuPage({
                default: true,
                title: 'Dashboard',
                url: '/dashboard/admin',
                icon: 'home',
                showDetails: false,
            }),
            new MenuPage({
                default: false,
                title: 'Produtos',
                url: '/products',
                icon: 'cart',
                showDetails: false,
            }),
            new MenuPage({
                default: false,
                title: 'Empreendimentos',
                url: "/enterprises",
                params: { mode: 'grid' },
                icon: 'business',
            }),
            new MenuPage({
                default: false,
                title: 'Cupons',
                url: '/coupons',
                icon: 'gift',
                showDetails: false,
            }),
            new MenuPage({
                default: false,
                title: 'Configurações',
                url: '/settings',
                icon: 'settings',
                showDetails: false,
            }),
        ];
    }
}
