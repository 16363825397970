import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'pages/account/auth/shared/auth.guard';
import { UnauthGuard } from 'pages/account/auth/shared/unauth.guard';
import { UserSettingsGuard } from 'pages/account/auth/shared/user.settings.guard';
import { NotFoundPage } from 'pages/misc/errors/not-found.page';
import { PermissionDeniedPage } from 'pages/misc/errors/permission-denied.page';
import { ServerErrorPage } from 'pages/misc/errors/server-error.page';

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        pathMatch: 'full',
        children: [
            {
                path: '',
                canActivate: [UserSettingsGuard],
                children: [],
            },
        ],
    },
    {
        path: 'welcome',
        loadChildren: () => import('./pages/account/auth/welcome/welcome.module').then((m) => m.WelcomeModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/account/auth/shared/auth.module').then((m) => m.AuthModule),
        canActivate: [UnauthGuard],
    },
    {
        path: 'users',
        loadChildren: () => import('./pages/account/users/shared/user.module').then((m) => m.UserModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/misc/settings/shared/setting.module').then(m => m.SettingModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboards/shared/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'products',
        loadChildren: () => import('./pages/products/shared/product.module').then(m => m.ProductModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'enterprises',
        loadChildren: () => import('./pages/enterprises/shared/enterprise.module').then(m => m.EnterpriseModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'coupons',
        loadChildren: () => import('./pages/coupons/shared/coupon.module').then(m => m.CouponModule),
        canActivate: [AuthGuard]
    },
    {
        path: '403',
        component: PermissionDeniedPage,
        data: {
            title: '403 - Permissões Insuficientes'
        }
    },
    {
        path: '500',
        component: ServerErrorPage,
        data: {
            title: '500 - Erro Inesperado'
        }
    },
    {
        path: '**',
        component: NotFoundPage,
        data: {
            title: '404 - Página Não Encontrada'
        }
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
