import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { BaseModule } from 'core/shared/base.module';
import { WelcomePage } from 'pages/account/auth/welcome/welcome.page';

const routes: Routes = [
    {
        path: '',
        component: WelcomePage,
        data: {
            title: 'Bem-vindo'
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        FormsModule,
        IonicModule,
        BaseModule
    ],
    exports: [RouterModule],
    declarations: [
        WelcomePage,
    ]
})
export class WelcomeModule { }
